import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

export const FETCH_AUTHORS = 'api/FETCH_AUTHORS'
export const FETCH_AUTHORS_SUCCESS = 'api/FETCH_AUTHORS_SUCCESS'
export const FETCH_AUTHORS_ERROR = 'api/FETCH_AUTHORS_ERROR'
export const FETCH_AUTHORS_NOT_FOUND = 'api/FETCH_AUTHORS_NOT_FOUND'

const SERVER_AUTHORS_URL = typeof window !== 'undefined' ? '/data/content/authors/daily-voice' : process.env.RAZZLE_CONTENT + '/authors/daily-voice'

export async function fetchAuthorsAPI () {
  return axios.get(SERVER_AUTHORS_URL)
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('fetchAuthorsAPI:', err.message)
      throw err
    })
}

function * fetchAuthorsSaga () {
  try {
    const res = yield call(fetchAuthorsAPI)
    yield put({ type: FETCH_AUTHORS_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_AUTHORS_ERROR, payload: e.message })
  }
}

export function * watchFetchAuthors () {
  yield takeLatest(FETCH_AUTHORS, fetchAuthorsSaga)
}

export const fetchAuthors = () => ({ type: FETCH_AUTHORS, isFetching: true, hasFetched: false })

export const initialState = {
  didInvalidate: false,
  lastFetch: 0,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  is404: false,
  error: null,
  authors: null
}

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AUTHORS:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        is404: false,
        hasError: false,
        error: null
      })
    case FETCH_AUTHORS_NOT_FOUND:
      return Object.assign({}, state, {
        hasError: true,
        is404: true,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        authors: null
      })
    case FETCH_AUTHORS_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        is404: false,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
    case FETCH_AUTHORS_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        authors: payload,
        hasError: false,
        is404: false,
        error: null
      })
    default:
      return state
  }
}
