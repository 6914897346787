import { all } from 'redux-saga/effects'
import { watchFetchArticles } from './articles'
import { watchFetchMostReadArticles } from './mostReadArticles'
import { watchFetchArticleContent, watchFetchArticle, watchPreviewArticle, watchSubmitCompetition } from './article'
import { watchNavChanges, watchPrecache } from './app'
import { watchFetchStaticPage } from './staticPage'
import { watchFetchMagazineCover } from './magazineCover'
import { watchFeedback } from './feedback'
import { watchSearchArticles, watchSearchArticlesMore } from './search'
import { watchSubscribe } from './subscribe'
import { watchfetchEditorsChoiceArticles } from './editorsChoice'
import { watchFetchWeather } from './weather'
import { watchFetchAuthors } from './authors'
import { watchFetchAuthor } from './author'

export default function * rootSaga () {
  yield all([
    watchFetchArticleContent(),
    watchFetchArticle(),
    watchPreviewArticle(),
    watchFetchArticles(),
    watchFetchMostReadArticles(),
    watchNavChanges(),
    watchFeedback(),
    watchPrecache(),
    watchFetchStaticPage(),
    watchSubscribe(),
    watchSearchArticles(),
    watchSubmitCompetition(),
    watchSearchArticlesMore(),
    watchFetchMagazineCover(),
    watchfetchEditorsChoiceArticles(),
    watchFetchWeather(),
    watchFetchAuthors(),
    watchFetchAuthor()
  ])
}
