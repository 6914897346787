import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withRouter, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import loadable from '@loadable/component'
import { Workbox } from 'workbox-window'

import { Sidebar, Segment, Menu, Message } from 'semantic-ui-react'
import ScrollToTop from 'ion-scrolltotop'
import { Mobile } from 'ion-media'
import { url2section } from 'ion-sections'
import { enableSingleRequest, enableInterstitial, enableLazyLoad, enableMappings, disableMappings } from 'ion-ads'

import Header from './Shell/Header'
import Footer from './Shell/Footer'
import NavMenu from './Shell/NavMenu'

import FaviconApple from '../static/favicon/apple-touch-icon.png'
import Favicon32 from '../static/favicon/favicon-32x32.png'
import Favicon16 from '../static/favicon/favicon-16x16.png'
import FaviconSafari from '../static/favicon/safari-pinned-tab.svg'
import FaviconICO from '../static/favicon/favicon.ico'

import logo from '../static/general/daily-voice.jpg'

import { hideMenu, toggleMenu, toggleSearch, pageView, onSlotRenderEndedLeft, onSlotRenderEndedRight } from '../../store/app'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'

import 'semantic-ui-css/components/sidebar.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/menu.min.css'
import 'semantic-ui-css/components/message.min.css'
import 'semantic-ui-css/components/icon.min.css'
import '../styles/app.scss'

export const HomePage = loadable(() => import('../pages/LandingPages/HomePage'))
export const NotFound = loadable(() => import('../pages/NotFound'))
export const DefaultSection = loadable(() => import('./DefaultSection'))
export const Article = loadable(() => import('../pages/Article'))
export const StaticPage = loadable(() => import('../pages/StaticPage'))
export const SearchPage = loadable(() => import('../pages/SearchPage'))
export const Feedback = loadable(() => import('../pages/Feedback'))
export const WeatherPage = loadable(() => import('../pages/WeatherPage'))
export const AuthorsSectionPage = loadable(() => import('../pages/AuthorsSectionPage'))

// GPT.enableSingleRequest()

export class App extends Component {
  constructor (props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.state = {
      wingbannerLeftActive: false,
      wingbannerRightActive: false,
      hamburgerActive: false,
      useSmall: false
    }
    // this.props.pageView(this.props.location.pathname)
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    if (prevProps.location.pathname !== this.props.location.pathname) {
      setTimeout(() => {
        this.props.pageView(this.props.location.pathname)
      }, 0)
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const useSmall = window.scrollY > (125 + (this.state.useSmall ? -25 : 0))
    if (useSmall !== this.state.useSmall) { this.setState({ useSmall }) }
  }

  toggleSearch () {
    this.props.toggleSearch()
  }

  toggleMenu () {
    this.props.toggleMenu()
  }

  hideMenu () {
    if (this.props.showMenu) {
      this.props.hideMenu()
    }
  }

  render () {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const wb = new Workbox('/sw.js')
      // wb.addEventListener('activated', (event) => {
      //   // `event.isUpdate` will be true if another version of the service
      //   // worker was controlling the page when this version was registered.
      //   if (!event.isUpdate) {
      //     this.setState({
      //       wbMessage: 'This site is now available offline'
      //     })
      //     // If your service worker is configured to precache assets, those
      //     // assets should all be available now.
      //   } else {
      //     this.setState({
      //       wbMessage: 'This site has been updated.'
      //     })
      //   }
      // })
      // wb.addEventListener('message', (event) => {
      //   if (event.data.type === 'CACHE_UPDATED') {
      //     const { updatedURL } = event.data.payload
      // this.setState({
      //   wbMessage: `A newer version of ${updatedURL} is available!`
      // })
      //   }
      // })
      // wb.addEventListener('waiting', (event) => {
      //   this.setState({
      //     wbMessage: `A newer version of this site is available! Please reload your page to activate it.`
      //   })
      // })
      wb.register()
    }
    return (
      <>
        <div className='app'>
          <Helmet
            defaultTitle={process.env.RAZZLE_SITE_TITLE}
          >
            <html lang='en' />
            <link rel='icon' type='image/x-icon' href={FaviconICO} />
            <link rel='apple-touch-icon' sizes='180x180' href={FaviconApple} />
            <link rel='icon' type='image/png' sizes='32x32' href={Favicon32} />
            <link rel='icon' type='image/png' sizes='16x16' href={Favicon16} />
            <link rel='mask-icon' href={FaviconSafari} color='#5bbad5' />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='theme-color' content='#ffffff' />
            <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta name='msvalidate.01' content='5A0910AA1A12E32A5352933CF5FD96E9' />
            <meta property='fb:app_id' content='1915095138801045' />
            <meta property='og:type' content='article' />
            <meta charset='utf-8' />
            <meta property='og:title' content={process.env.RAZZLE_SITE_TITLE} />
            <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta property='og:image' content={logo} />
            <meta itemprop='inLanguage' content='en' />
            <meta itemprop='image' content={logo} />
            <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />
            <meta name='mobile-web-app-capable' content='yes' />
            <meta name='theme-color' content='#ffffff' />
            <meta name='google-site-verification' content='pzAffsof8VshOp53XmZa9qZwwSMWIzKq_U8TnBj93U8' />
            <meta itemprop='image' content={logo} />
            <meta itemprop='thumbnailUrl' content={logo} />
            <meta name='twitter:site' content={process.env.RAZZLE_SITE_TWITTER_USER} />
            <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER_USER} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image:src' content={logo} />
            <link rel='canonical' itemprop='url' href={process.env.RAZZLE_SITE_URL + (this.props.location.pathname === '/' ? '' : this.props.location.pathname)} />
            <body className={(this.props.showMenu ? 'menu-open' : 'menu-closed') + ((this.props.wingbannerLeftActive || this.props.wingbannerRightActive) ? ' dfp-take-over-ad' : '')} />
          </Helmet>
          <Sidebar.Pushable as={Segment}>
            <Sidebar as={Menu} animation='push' width='wide' visible={this.props.showMenu} icon='labeled' vertical>
              <Mobile>
                <NavMenu toggleMenu={this.toggleMenu} />
              </Mobile>
            </Sidebar>
            <Sidebar.Pusher dimmed={this.props.showMenu} onClick={() => this.hideMenu}>
              <Header useSmall={this.state.useSmall} toggleMenu={this.toggleMenu} toggleSearch={this.toggleSearch} {...this.props} />
              <main id='main-content' role='main' className='content'>
                <ScrollToTop>
                  {this.state.wbMessage &&
                    <Message floating info icon onDismiss={(event, data) => this.setState({ wbMessage: '' })}>{this.state.wbMessage}</Message>}
                  <Switch>
                    <Route exact path='/shell' render={() => <div>Loading...</div>} />
                    <Route exact path='/feedback/thankyou' render={() => <Feedback thanksMessage='Thank you for your feedback. We will get back to you shortly.' />} />
                    <Route exact path='/feedback' component={Feedback} />
                    <Route exact path='/' render={p => <HomePage {...this.props} />} />
                    <Route
                      path='/authors' exact render={props =>
                        <AuthorsSectionPage
                          section='authors'
                          title='Authors Page'
                          description='Authors Page'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/search' render={(props) =>
                        <SearchPage
                          {...this.props}
                          twitterName={process.env.RAZZLE_SITE_TWITTER_USER}
                          titleTemplate={'%s | ' + process.env.RAZZLE_SITE_TITLE}
                        />}
                    />
                    <Route
                      exact path='/:page(terms-and-conditions)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Terms and Conditions | The Daily Voice'
                          description='By making use of this website, you agree to and acknowledge the terms of use set out in The Daily Voice terms and conditions.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(privacy-policy)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Privacy Policy | The Daily Voice'
                          description='By making use of this website, you agree to and acknowledge the terms of use set out in The Daily Voice terms and conditions.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(contact-us)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Contact Us | Get In Touch | The Daily Voice'
                          description='Get in touch, we would love to hear from you! Visit our website to find out how you can get in contact with The Daily Voice team.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(about-us)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='About Us | The Daily Voice'
                          description='About The Daily Voice - Ons Skrik Vir Niks!'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(complaints)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='The Independent Media Complaints Procedure | The Daily Voice'
                          description='The Independent Media Complaints Procedure'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(rss-feeds)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='RSS Feeds | The Daily Voice'
                          description='About The Daily Voice - Ons Skrik Vir Niks!'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/weather' render={props =>
                        <WeatherPage
                          title='Weather | The Daily Voice'
                          description='Today’s weather forecast in Cape Town - Ons Skrik Vir Niks!'
                          {...this.props}
                        />}
                    />
                    {/* Defaults and direct article access */}
                    <Route path='/preview' render={props => <Article {...this.props} {...props} url='preview' contentKey={999999} />} />
                    <Route path='/:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:contentKey([0-9]{5,})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)/' strict exact render={props => <Redirect to={'/' + props.match.params.section} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)' render={props => <DefaultSection {...this.props} {...props} twitterName={process.env.RAZZLE_SITE_TWITTER_USER} />} />
                    {/* <Route exact path='/404' component={NotFound} {...this.props} /> */}
                    <Route path='*' component={NotFound} {...this.props} />
                  </Switch>
                </ScrollToTop>
              </main>
              <Footer showSubscribe={(this.props.location.pathname !== '/subscribe/newsletters')} section={url2section(this.props.location.pathname)} />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </>
    )
  }
}

/**
 * enable Single Request Mode for Ads
 *
 * This setting requires a call to `performSingleRequest` to initiate the fetch. This is currently achieved using sagas,
 * leveraging `onSlotRegistered` to trigger an action, and debouncing the events to catch the last one in the series to
 * trigger the `performSingleRequest` call.
 */
enableSingleRequest()
enableInterstitial()
if (process.env.RAZZLE_ENABLE_GAM && process.env.RAZZLE_ENABLE_LAZYLOAD) {
  enableLazyLoad()
}
if (process.env.RAZZLE_ENABLE_MAPPINGS) {
  enableMappings()
} else {
  disableMappings()
}

const mapStateToProps = (state) => (state.app)
const mapDispatchToProps = (dispatch) => bindActionCreators({ hideMenu, toggleMenu, toggleSearch, pageView, fetchArticles, fetchMoreArticles, onSlotRenderEndedLeft, onSlotRenderEndedRight }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
